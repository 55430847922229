"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const node_1 = require("memfs/lib/node");
// Make emit synchronous
node_1.Node.prototype.emit = function (event, ...args) {
    const listeners = this.listeners(event);
    for (let listener of listeners) {
        try {
            listener(...args);
        }
        catch (e) {
            console.error(e);
        }
    }
    return listeners.length > 0;
};
